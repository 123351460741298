import { GameStats } from '@wt/game/providers/user/types';
import dayjs from 'dayjs';

import { Guess } from '../_components/types';
import { getGameScore } from './getGameScore';

export const getUpdatedStats = (
  currentStats: GameStats,
  todaysGuesses: Guess[],
  dayString: string
): GameStats => {
  const newStats: GameStats = { ...currentStats };

  // games played
  newStats.gamesPlayed++;

  // last game played
  newStats.lastGamePlayed = dayString;

  // current streak
  if (currentStats.lastGamePlayed) {
    if (
      dayjs(currentStats.lastGamePlayed)
        .add(1, 'day')
        .isSame(dayjs(dayString), 'day')
    ) {
      newStats.currentStreak++;
    } else {
      newStats.currentStreak = 1;
    }
  } else {
    newStats.currentStreak = 1;
  }

  const todaysTotalScore = todaysGuesses.reduce((c, guess) => {
    const roundScoreResult = getGameScore(guess);
    return c + roundScoreResult.score.total;
  }, 0);
  const todaysYearScore = todaysGuesses.reduce((c, guess) => {
    const roundScoreResult = getGameScore(guess);
    return c + roundScoreResult.score.year;
  }, 0);
  const todaysLocationScore = todaysGuesses.reduce((c, guess) => {
    const roundScoreResult = getGameScore(guess);
    return c + roundScoreResult.score.distance;
  }, 0);

  // highest location score
  newStats.highestLocationScore = Math.max(
    newStats.highestLocationScore,
    todaysLocationScore
  );

  // highest year score
  newStats.highestYearScore = Math.max(
    newStats.highestYearScore,
    todaysYearScore
  );

  // highest total score
  newStats.highestTotalScore = Math.max(
    newStats.highestTotalScore,
    todaysTotalScore
  );

  // total location score
  newStats.totalLocationScore += todaysLocationScore;

  // total year score
  newStats.totalYearScore += todaysYearScore;

  return newStats;
};
