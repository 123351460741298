import React, { PropsWithChildren, SVGProps } from "react";

const GlobeIcon = (props: PropsWithChildren<SVGProps<SVGSVGElement>>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.5 47.5"
    width="1em"
    height="1em"
    xmlSpace="preserve"
    {...props}
  >
    <defs id="defs6">
      <clipPath id="clipPath18">
        <path d="M 0,38 38,38 38,0 0,0 0,38 z" id="path20" />
      </clipPath>
      <clipPath id="clipPath30">
        <path
          d="M 1,19 C 1,9.059 9.059,1 19,1 l 0,0 c 9.941,0 18,8.059 18,18 l 0,0 c 0,9.941 -8.059,18 -18,18 l 0,0 C 9.059,37 1,28.941 1,19"
          id="path32"
        />
      </clipPath>
    </defs>
    <g transform="matrix(1.25,0,0,-1.25,0,47.5)" id="g12">
      <g id="g14">
        <g clipPath="url(#clipPath18)" id="g16">
          <g transform="translate(37,19)" id="g22">
            <path
              d="m 0,0 c 0,-9.941 -8.059,-18 -18,-18 -9.941,0 -18,8.059 -18,18 0,9.941 8.059,18 18,18 C -8.059,18 0,9.941 0,0"
              id="path24"
              style={{
                fill: "#88c9f9",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
          </g>
        </g>
      </g>
      <g id="g26">
        <g clipPath="url(#clipPath30)" id="g28">
          <g transform="translate(28.9375,35.4375)" id="g34">
            <path
              d="m 0,0 c -2.305,0.943 -3.375,-1.937 -5.25,-1.375 -1.875,0.563 -4.375,1.812 -6,0.25 -1.625,-1.563 -2,-3 0,-2.937 2,0.062 3.375,2.437 4.375,1.437 1,-1 0.75,-1.812 -1.625,-2.125 -2.375,-0.312 -4.938,-0.687 -6.313,-0.687 -1.374,0 -1.687,-0.813 -0.75,-1.688 0.938,-0.875 -0.562,-0.938 -2.124,-1.813 -1.563,-0.875 0.375,-1.25 1.687,-2 1.312,-0.75 2.312,0.188 2.875,1.438 0.562,1.25 2.98,2.75 3.99,2.563 1.01,-0.188 1.01,-0.688 0.822,-1.563 -0.187,-0.875 0.751,-0.625 0.813,0.375 0.063,1 1.188,1.75 2.063,1.813 0.875,0.062 1.624,-1.188 0.625,-1.813 -1,-0.625 -2,-1.125 -0.75,-1.438 1.25,-0.312 2.124,-1.937 0.687,-2.625 -1.437,-0.687 -3.938,-1.125 -5.063,-0.562 -1.125,0.562 -3.687,1.375 -4.375,0.937 -0.687,-0.437 -1.062,-0.889 -1.874,-1.194 -0.813,-0.306 -4.125,-1.806 -4.188,-3.743 -0.062,-1.937 -0.125,-4.125 1.188,-4.187 1.312,-0.063 4.499,0.812 5.499,1.625 1,0.812 2.375,0.625 2.813,-0.313 0.437,-0.937 0.125,-1.5 -0.313,-3 -0.437,-1.5 0.287,-2.25 0.987,-3.562 0.701,-1.313 1.263,-2.063 1.263,-3 0,-0.938 1.001,-1.875 2.501,-0.313 1.5,1.563 2.874,4.625 3.499,5.75 0.625,1.125 1.126,3.625 1.876,4.125 0.75,0.5 1.937,1.688 1.062,1.5 -0.875,-0.187 -2.625,0.063 -3.063,1.313 -0.437,1.249 -2.312,3.624 -1.437,3.874 0.875,0.25 1.875,-1.389 2.25,-2.163 0.375,-0.774 0.875,-1.711 1.625,-1.961 0.75,-0.25 2.375,1.672 2.875,1.961 0.5,0.288 0.125,1.476 -0.875,1.351 -1,-0.125 -2.312,0 -2.312,0.625 0,0.625 1.249,1.437 2.249,1.25 1.001,-0.188 1.751,-0.5 2.376,-1.25 0.624,-0.75 1.874,-2.125 2.374,-3 0.5,-0.875 0.875,-1 1.125,0.562 0.25,1.563 0.625,2.063 0.876,3.25 C 8.313,-11.125 5.5,-2.25 0,0"
              id="path36"
              style={{
                fill: "#5c913b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default GlobeIcon;
