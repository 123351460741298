import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { TeuteufTooltip } from '@wt/game/providers/tooltips';
import { useUserProvider } from '@wt/game/providers/user/UserProvider';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export const AccountAvatar = () => {
  const { loggedIn, user } = useUserProvider();

  return (
    <>
      {loggedIn ? <AccountPicture imageUrl={user.photoURL} /> : <LoginButton />}
    </>
  );
};

const LoginButton = () => {
  const { showLoginTooltip, setShowLoginToolip } = useUserProvider();

  return (
    <TeuteufTooltip
      id="login"
      onceOnly={false}
      active={showLoginTooltip}
      localStorageKey="hide-login-tooltip"
      text="Log in to make sure you save your stats!"
      onClose={() => {
        setShowLoginToolip(false);
      }}
    >
      <Link href={'/login'}>
        <AccountBoxOutlinedIcon fontSize="large" className="hidden md:flex" />
        <AccountBoxOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
    </TeuteufTooltip>
  );
};

const AccountPicture = ({ imageUrl }: { imageUrl: string }) => {
  const [imgSrc, setImgSrc] = useState<string>(imageUrl);

  useEffect(() => {
    if (imgSrc !== imageUrl) {
      setImgSrc(imageUrl);
    }
  }, [imageUrl]);

  const handleError = () => {
    setImgSrc('fallbackIcon.svg');
  };

  return (
    <Link href={'/account'} className="flex items-center justify-center">
      <div className="relative size-[24px] overflow-hidden rounded-full md:size-[35px]">
        {imgSrc !== 'fallbackIcon.svg' && !isEmpty(imgSrc) ? (
          <img
            src={imgSrc}
            referrerPolicy="no-referrer"
            onError={handleError}
            aria-label="Account"
            className="absolute inset-0 size-full object-cover object-center"
          />
        ) : (
          <AccountBoxOutlinedIcon className="fill-current text-3xl" />
        )}
      </div>
    </Link>
  );
};
