export interface GameStats {
  gamesPlayed: number;
  lastGamePlayed: string | null;
  currentStreak: number;
  highestLocationScore: number;
  highestYearScore: number;
  highestTotalScore: number;
  totalLocationScore: number;
  totalYearScore: number;
}

export interface User extends GameStats {
  username: string;
  usernameLastChangedAt: Date | null;
  firstName: string;
  lastName: string;
  email: string;
  photoURL: string;
  marketingOptIn: boolean;
  isPremium: boolean;
  isInfluencer: boolean;
  bio?: string;
  socials?: { platform: string; handle: string }[];
}

export interface DatabaseUser extends User {
  socialID: string;
  firstLogin: boolean;
  dailyGuesses: string;
  dailyGamesCompleted: string;
  archiveGuesses: string;
  archiveGamesCompleted: string;
  communityGuesses: string;
  communityGamesCompleted: string;
}

export const initialUser: User = {
  username: '',
  usernameLastChangedAt: null,
  firstName: '',
  lastName: '',
  email: '',
  photoURL: '',
  marketingOptIn: false,
  isPremium: false,
  isInfluencer: false,
  gamesPlayed: 0,
  lastGamePlayed: null,
  currentStreak: 0,
  highestLocationScore: 0,
  highestYearScore: 0,
  highestTotalScore: 0,
  totalLocationScore: 0,
  totalYearScore: 0,
};

export const convertDbUserToUser = (dbUser: DatabaseUser): User => {
  return {
    username: dbUser.username,
    usernameLastChangedAt: dbUser.usernameLastChangedAt,
    firstName: dbUser.firstName,
    lastName: dbUser.lastName,
    email: dbUser.email,
    photoURL: dbUser.photoURL,
    bio: dbUser.bio,
    socials: dbUser.socials,
    marketingOptIn: dbUser.marketingOptIn,
    isPremium: dbUser.isPremium,
    isInfluencer: dbUser.isInfluencer,
    gamesPlayed: dbUser.gamesPlayed,
    lastGamePlayed: dbUser.lastGamePlayed,
    currentStreak: dbUser.currentStreak,
    highestLocationScore: dbUser.highestLocationScore,
    highestYearScore: dbUser.highestYearScore,
    highestTotalScore: dbUser.highestTotalScore,
    totalLocationScore: dbUser.totalLocationScore,
    totalYearScore: dbUser.totalYearScore,
  };
};

export const getUserStats = (user: User): GameStats => {
  return {
    gamesPlayed: user.gamesPlayed,
    lastGamePlayed: user.lastGamePlayed,
    currentStreak: user.currentStreak,
    highestLocationScore: user.highestLocationScore,
    highestYearScore: user.highestYearScore,
    highestTotalScore: user.highestTotalScore,
    totalLocationScore: user.totalLocationScore,
    totalYearScore: user.totalYearScore,
  };
};

// if fields are added to the User interface in the future
// this function will default the new values to the values on initialUser
// and keep the values from local storage
export const parseUserFromLocalStorage = (
  userFromLocalStorage: string
): User => {
  const parsedUser: Partial<User> = JSON.parse(userFromLocalStorage);
  return {
    ...initialUser,
    ...parsedUser,
  };
};