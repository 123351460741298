import "./spinner.css";

import React from "react";

export const Spinner = (props: { zoom?: number }) => {
  return (
    <>
      <div className="lds-roller" style={{ zoom: props.zoom }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export const FullPageSpinner = () => {
  return (
    <div className="fixed inset-0 z-[9999]  flex items-center justify-center bg-black/50 backdrop-blur-md">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
