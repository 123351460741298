import Icon from "@wt/game/assets/logo/WhenTakenLogo/icon";

export const WhenTakenLogo = () => {
  return (
    <div
      className='relative top-1 inline-flex flex-row items-center gap-1.5'
    >
      <div>
        <Icon width="1em" height="1em" />
      </div>
      <div className="hidden md:flex">
        <strong className="font-semibold leading-none">When</strong>
        <span className="font-light  leading-none">Taken</span>
      </div>
    </div>
  );
};
