import {
  saveArchiveGamesCompleted,
  saveArchiveGuesses,
  saveDailyGamesCompleted,
} from '@wt/utilities/database';
import dayjs from 'dayjs';

import {
  archiveGuessesStorageKey,
  loadAllGuesses,
} from '../game/_components/types';

export enum EArchiveCalendarState {
  noPuzzle,
  hasPuzzle,
  upcoming,
}

export const archiveCalendarBackgroundColor = (
  state: EArchiveCalendarState
): string => {
  switch (state) {
    case EArchiveCalendarState.upcoming:
      return 'bg-pale-highlight';
    case EArchiveCalendarState.hasPuzzle:
      return 'bg-primary';
    case EArchiveCalendarState.noPuzzle:
      return 'bg-[#F7A1A1]';
  }
};

export const dailyGamesCompletedStorageKey = 'daily-games-completed';
export const archiveGamesCompletedStorageKey = 'archive-games-completed';

export function loadDailyCompleted(): string[] {
  const storedCompleted = localStorage.getItem(dailyGamesCompletedStorageKey);
  try {
    return storedCompleted ? JSON.parse(storedCompleted) : [];
  } catch (e) {
    return [];
  }
}

export function setDailyCompleted(dayString: string, loggedIn: boolean): void {
  const allCompleted = loadDailyCompleted();
  const newCompleted = JSON.stringify([...allCompleted, dayString]);
  localStorage.setItem(dailyGamesCompletedStorageKey, newCompleted);
  if (loggedIn) {
    saveDailyGamesCompleted({ dailyGamesCompleted: newCompleted });
  }
  return;
}

export function loadArchiveCompleted(): string[] {
  const storedCompleted = localStorage.getItem(archiveGamesCompletedStorageKey);
  try {
    return storedCompleted ? JSON.parse(storedCompleted) : [];
  } catch (e) {
    return [];
  }
}

export function setArchiveCompleted(
  dayString: string,
  loggedIn: boolean
): void {
  const allCompleted = loadArchiveCompleted();
  const newCompleted = JSON.stringify([...allCompleted, dayString]);
  localStorage.setItem(archiveGamesCompletedStorageKey, newCompleted);
  if (loggedIn) {
    saveArchiveGamesCompleted({ archiveGamesCompleted: newCompleted });
  }
  return;
}

export function clearArchiveGame(dayString: string, loggedIn: boolean): void {
  const allCompleted = loadArchiveCompleted();
  const filteredCompleted = JSON.stringify(
    ...[allCompleted.filter((d) => d !== dayString)]
  );
  localStorage.setItem(archiveGamesCompletedStorageKey, filteredCompleted);
  if (loggedIn) {
    saveArchiveGamesCompleted({ archiveGamesCompleted: filteredCompleted });
  }

  const allArchiveGuesses = loadAllGuesses('archive');
  delete allArchiveGuesses[dayString];
  const newGuesses = JSON.stringify({
    ...allArchiveGuesses,
  });
  localStorage.setItem(archiveGuessesStorageKey, newGuesses);
  if (loggedIn) {
    saveArchiveGuesses({ archiveGuesses: newGuesses });
  }
  return;
}

export function hasGameBeenCompleted(
  completed: string[],
  date: string | Date
): boolean {
  return completed.includes(formatDate(date));
}

export const formatDate = (date: string | Date): string => {
  return dayjs(date).format('YYYY-MM-DD');
};
