'use client' // Error components must be Client Components

import {PageTemplate} from "@wt/game/templates";
import { feedbackLink } from "@wt/utilities/feedbackLink";
import Link from "next/link";
import { useEffect } from 'react'

export default function Error({ error, usePageTemplate }: {
  error?: string,
  usePageTemplate?: boolean
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <>
      {usePageTemplate === false
        ? <ErrorPageComponents error={error} />
        : <PageTemplate>
            <ErrorPageComponents error={error} />
          </PageTemplate>
      }
    </>
  )
}

function ErrorPageComponents({ error }: {
  error?: string
}) {
  return (
    <section className="flex size-full flex-col items-center justify-center">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
          <div className="mx-auto max-w-screen-sm text-center">
              {!error &&
                <>
                  <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-accent lg:text-9xl">500</h1>
                  <p className="mb-4 text-3xl font-bold tracking-tight  md:text-4xl ">Internal Server Error.</p>
                  <p className="mb-4 text-lg font-light text-base-content/90">We are already working to solve the problem.</p>
                </>}
              {error &&
                <p className="mb-8 text-3xl font-bold tracking-tight md:text-4xl">{error}</p>
              }
          </div>
          <div className="grid grid-cols-2 justify-center gap-4 md:gap-6 lg:gap-12">
              <Link
                  href={"/"}
                  prefetch={true}
                  className="flex h-12 items-center justify-center gap-2.5 rounded-lg bg-accent px-4 py-2.5 md:h-14 md:rounded-xl md:px-6 md:py-4"
              >
                  <div className="text-base font-bold  uppercase text-white sm:text-xl md:text-xl">
                      Home
                  </div>
              </Link>
              <Link
                  href={feedbackLink} target={"_blank"} title="Leave Feedback"
                  className="flex h-12 items-center justify-center gap-2.5 rounded-lg border-2 bg-white px-4 py-2.5 md:h-14 md:rounded-xl md:px-6 md:py-4 "
              >
                  <div className="whitespace-nowrap text-base font-bold uppercase text-slate-900 sm:text-xl  md:text-xl">
                      Feedback
                  </div>
              </Link>
          </div>
      </div>
    </section>
  )
}