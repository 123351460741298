import {
  saveCommunityGamesCompleted,
  saveCommunityGuesses,
  saveCommunityVote,
} from '@wt/utilities/database';

import { loadAllGuesses } from '../game/_components/types';

export const communityGuessesStorageKey = 'community-guesses';
export const communityGamesCompletedStorageKey = 'community-games-completed';
export const communityGamesVotesStorageKey = 'community-games-votes';

export function loadCommunityCompleted(): string[] {
  const storedCompleted = localStorage.getItem(
    communityGamesCompletedStorageKey
  );
  try {
    return storedCompleted ? JSON.parse(storedCompleted) : [];
  } catch (e) {
    return [];
  }
}

export function loadCommunityVotes(): Record<string, number> {
  const storedVotes = localStorage.getItem(communityGamesVotesStorageKey);
  try {
    return storedVotes ? JSON.parse(storedVotes) : {};
  } catch (e) {
    return {};
  }
}

export function setCommunityCompleted(
  puzzleId: string,
  loggedIn: boolean
): void {
  const allCompleted = loadCommunityCompleted();
  const newCompleted = JSON.stringify([...allCompleted, puzzleId]);
  localStorage.setItem(communityGamesCompletedStorageKey, newCompleted);
  if (loggedIn) {
    saveCommunityGamesCompleted({ communityGamesCompleted: newCompleted });
  }
  return;
}

export function setCommunityVote(puzzleId: string, delta: number): void {
  const allVotes = loadCommunityVotes();
  if (allVotes[puzzleId]) return;
  const newVotes = { ...allVotes, [puzzleId]: delta };
  localStorage.setItem(communityGamesVotesStorageKey, JSON.stringify(newVotes));
  console.log({ communityGamesVotesStorageKey });
  saveCommunityVote({ slug: puzzleId, delta });
}

export function clearCommunityGame(slug: string, loggedIn: boolean): void {
  const allCompleted = loadCommunityCompleted();
  const filteredCompleted = JSON.stringify(
    ...[allCompleted.filter((d) => d !== slug)]
  );
  localStorage.setItem(communityGamesCompletedStorageKey, filteredCompleted);
  if (loggedIn) {
    saveCommunityGamesCompleted({ communityGamesCompleted: filteredCompleted });
  }

  const allCommunityGuesses = loadAllGuesses('community');
  delete allCommunityGuesses[slug];
  const newGuesses = JSON.stringify({
    ...allCommunityGuesses,
  });
  localStorage.setItem(communityGuessesStorageKey, newGuesses);
  if (loggedIn) {
    saveCommunityGuesses({ communityGuesses: newGuesses });
  }
  return;
}