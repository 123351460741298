import { createContext, useContext } from "react";

export interface SettingsStateProps {
  distanceUnit: "km" | "miles" | string;
  showAnswers: boolean;
  showDayShift: boolean;
  dayShiftCount: number;
}
export const defaultSettings: SettingsStateProps = {
  distanceUnit: 'km',
  showAnswers: false,
  showDayShift: false,
  dayShiftCount: 0,
};

type SettingPartialFunc =
  | ((newData: Partial<SettingsStateProps>) => void)
  | (() => void);

export type SettingsContextProps = [
  SettingsStateProps | undefined,
  SettingPartialFunc,
];
export const SettingsContext = createContext<SettingsContextProps>([
  defaultSettings,
  () => {
    /* */
  },
]);

export default function useSettingsContext() {
  return useContext(SettingsContext);
}
