import { GameMode } from '@wt/game/utils/types';
import { PuzzleLocation } from '@wt/shared/components/maps/mapkit/types';
import { getCDNURL, getSiteMode } from '@wt/utilities/siteMode';

const loadedPuzzles: Record<string, PuzzleLocation[]> = {};
const loadedCustomPuzzles: Record<string, PuzzleLocation[]> = {};

const getDatePuzzle = async (id: string): Promise<PuzzleLocation[]> => {
  if (id in loadedPuzzles && loadedPuzzles[id]) {
    return loadedPuzzles[id];
  }
  const puzzles = await fetch(`${getCDNURL()}/puzzles/${id}.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    next: {
      tags: ['getPuzzle', `${id}`],
    },
  });

  const puzzlesJson: PuzzleLocation[] = !puzzles.ok
    ? new Array<PuzzleLocation>()
    : await puzzles.json();
  loadedPuzzles[id] = puzzlesJson;
  return puzzlesJson as PuzzleLocation[];
};

const getCustomPuzzle = async (id: string): Promise<PuzzleLocation[]> => {
  if (id in loadedCustomPuzzles && loadedCustomPuzzles[id]) {
    return loadedCustomPuzzles[id];
  }
  const puzzles = await fetch(
    `${getCDNURL()}/custom-puzzles/${id}/${id}.json?v=${new Date().getTime()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      next: {
        tags: ['getCustomPuzzle', `${id}`],
      },
    }
  );

  const puzzlesJson: PuzzleLocation[] = !puzzles.ok
    ? new Array<PuzzleLocation>()
    : await puzzles.json();
  loadedCustomPuzzles[id] = puzzlesJson;
  return puzzlesJson as PuzzleLocation[];
};

export const getPuzzle = async (
  id: string,
  mode: GameMode = 'daily'
): Promise<PuzzleLocation[]> => {
  if (mode == 'community') return getCustomPuzzle(id);
  return getDatePuzzle(id);
};

// when a puzzle is edited, clear it from loaded puzzles
export const clearLoadedCustomPuzzle = (id: string): void => {
  if (id in loadedCustomPuzzles && loadedCustomPuzzles[id]) {
    delete loadedCustomPuzzles[id];
  }
  return;
};
