'use client';
import { getMaxScore } from '@wt/app/game/_utils/getGameScore';
import './round-progress.css';

import { PUZZLE_LENGTH } from '@wt/app/game/_components/PuzzleLoader';
import { useRoundProgress } from '@wt/game/providers/roundProgress/RoundProgressProvider';
import { GameMode } from '@wt/game/utils/types';
import useAnimatedRouter from '@wt/shared/hooks/useAnimatedRouter';
import { memo } from 'react';

const RoundSteps = memo(function RoundSteps(props: {
  elements: JSX.Element[];
}) {
  return (
    <ol className="wt-round-steps transition-opacity  duration-150 ease-linear">
      {props.elements}
    </ol>
  );
});

export const RoundProgress = memo(function RoundProgress({
  gameMode,
}: {
  gameMode: GameMode;
}) {
  const { animatedRoute } = useAnimatedRouter();
  const { currentRound, numRoundsCompleted, viewingResults, puzzleId, score } =
    useRoundProgress();

  const isDaily = gameMode === 'daily';

  const gotoRound = (e: any) => {
    const target = e.currentTarget;
    if (target && target instanceof HTMLSpanElement) {
      const r = target.getAttribute('data-index');
      e.preventDefault();
      e.stopPropagation();
      if (Number(r) <= numRoundsCompleted) {
        !isDaily && puzzleId
          ? animatedRoute(`/${gameMode}/game/${puzzleId}/results/${r}`)
          : animatedRoute('/game/results/' + r);
      } else if (Number(r) === numRoundsCompleted + 1) {
        !isDaily && puzzleId
          ? animatedRoute(`/${gameMode}/game/${puzzleId}`)
          : animatedRoute('/game');
      }
    }
    return false;
  };

  return (
    <section className="flex size-full flex-row items-center justify-center gap-x-6 leading-none">
      <div className="wt-round-header-segment">
        <div className="flex items-center transition-opacity duration-150 ease-linear">
          <label className="wt-round-header-label">Round</label>
          <div className="grow items-center">
            <RoundSteps
              elements={Array.from({ length: PUZZLE_LENGTH }).map(
                (r, index) => {
                  const roundNumber = index + 1;
                  let cls = 'wt-round-step';

                  if (roundNumber <= numRoundsCompleted) {
                    cls += ' active';
                  }
                  if (roundNumber === numRoundsCompleted && viewingResults) {
                    cls += ' most-recent';
                  }
                  if (roundNumber === currentRound) {
                    cls += ' current';
                  }
                  if (viewingResults) {
                    cls += ' results';
                  }
                  if (roundNumber === numRoundsCompleted + 1) {
                    cls += ' next-round';
                  }

                  return (
                    <li key={'round-' + index} className={cls}>
                      <span onClick={gotoRound} data-index={index + 1}>
                        {index + 1}
                      </span>
                    </li>
                  );
                }
              )}
            />
          </div>
        </div>
      </div>
      <div className="wt-round-header-segment">
        <div className="flex items-center transition-opacity duration-150 ease-linear">
          <label className="wt-round-header-label">Score</label>
          <div className="flex items-end">
            <span className="text-2xl font-bold">{score}</span>
            <span className="text-base font-semibold text-info-content">
              /{getMaxScore() * PUZZLE_LENGTH}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
});
