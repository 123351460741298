import { setArchiveCompleted, setDailyCompleted } from '@wt/app/archive/utils';
import { setCommunityCompleted } from '@wt/app/community/utils';
import { communityGuessesStorageKey } from '@wt/app/community/utils';
import { GameMode } from '@wt/game/utils/types';
import { PuzzleLocation } from '@wt/shared/components/maps/mapkit/types';
import { Coordinate } from '@wt/shared/libs/mapkit';
import { getCookie } from '@wt/utilities/cookies';
import {
  saveArchiveGuesses,
  saveCommunityFinished,
  saveCommunityGuesses,
  saveCommunityRoundFinished,
  saveDailyGuesses,
} from '@wt/utilities/database';

import { getGameScore } from '../_utils/getGameScore';
import { PUZZLE_LENGTH } from './PuzzleLoader';

export interface PuzzleResultsState {
  date: string;
  device: string;
  guesses: Guess[] | undefined;
  score: number;
  round: number;
  currentRound?: number;
  puzzle: PuzzleLocation[];
}

export interface Guess {
  puzzle: PuzzleLocation;
  year: number;
  location: Coordinate;
  locationSecondary?: Coordinate;
  correctCountry?: boolean;
  hintsUsed?: string[];
}

export const dailyGuessesStorageKey = 'daily-guesses';
export const archiveGuessesStorageKey = 'archive-daily-guesses';

export function loadAllGuesses(
  gameMode: GameMode = 'daily'
): Record<string, Guess[]> {
  let storedGuesses;
  if (gameMode == 'daily') {
    storedGuesses = localStorage.getItem(dailyGuessesStorageKey);
  } else if (gameMode == 'archive') {
    storedGuesses = localStorage.getItem(archiveGuessesStorageKey);
  } else if (gameMode == 'community') {
    storedGuesses = localStorage.getItem(communityGuessesStorageKey);
  }
  try {
    return storedGuesses ? JSON.parse(storedGuesses) : {};
  } catch (e) {
    // If there is invalid JSON in the db, don't break when parsing it
    return {};
  }
}

export function saveGuesses(
  puzzleId: string,
  guesses: Guess[],
  gameMode: GameMode
): void {
  const isLoggedIn = getCookie('isLoggedIn') === 'true';

  if (gameMode == 'archive' || gameMode == 'community') {
    if (puzzleId === undefined) {
      throw new Error('Puzzle id is undefined');
    }
    const allGuesses = loadAllGuesses(gameMode);
    const newGuesses = JSON.stringify({
      ...allGuesses,
      [puzzleId]: guesses,
    });

    if (gameMode == 'archive') {
      localStorage.setItem(archiveGuessesStorageKey, newGuesses);
      if (isLoggedIn) saveArchiveGuesses({ archiveGuesses: newGuesses });

      if (guesses.length === PUZZLE_LENGTH) {
        setArchiveCompleted(puzzleId, isLoggedIn);
      }
    } else if (gameMode == 'community') {
      localStorage.setItem(communityGuessesStorageKey, newGuesses);

      if (isLoggedIn) saveCommunityGuesses({ communityGuesses: newGuesses });
      if (guesses.length === PUZZLE_LENGTH) {
        setCommunityCompleted(puzzleId, isLoggedIn);
        // Save score to db
        let totalLocationScore = 0;
        let totalYearScore = 0;
        guesses.forEach((pg) => {
          const ps = getGameScore(pg);
          totalLocationScore += ps.score.distance;
          totalYearScore += ps.score.year;
        });
        saveCommunityFinished({
          slug: puzzleId,
          totalLocationScore,
          totalYearScore,
        });
      } else {
        // increment timesPlayed for community games after each guess
        saveCommunityRoundFinished({
          slug: puzzleId,
          round: guesses.length,
        });
      }
    }
    return;
  }

  // only save todays guesses
  const newGuesses = JSON.stringify({
    [puzzleId]: guesses,
  });
  localStorage.setItem(dailyGuessesStorageKey, newGuesses);
  if (isLoggedIn) saveDailyGuesses({ dailyGuesses: newGuesses });
  if (guesses.length === PUZZLE_LENGTH) {
    setDailyCompleted(puzzleId, isLoggedIn);
  }
  return;
}
