import { nullFunc } from '@wt/utilities/nullFunction';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useUserProvider } from '../user/UserProvider';

interface BannerAdOpenContextType {
  adOpen: boolean;
  setAdOpen: (value: boolean) => void,
}

const initialContextValue: BannerAdOpenContextType = {
  adOpen: true,
  setAdOpen: nullFunc,
};

const BannerAdOpenContext = createContext<BannerAdOpenContextType>(initialContextValue);

export const useBannerAdOpen = () => useContext(BannerAdOpenContext);

export const BannerAdOpenProvider = ({ children }: { children: ReactNode }) => {
  const [adOpen, setAdOpen] = useState<boolean>(true);

  const { user } = useUserProvider();

  useEffect(() => {
    if (user.isPremium && adOpen) {
      setAdOpen(false);
    }
  }, [user.isPremium]);

  const externalSetAdOpen = (value: boolean) => {
    if (!user.isPremium) {
      setAdOpen(value);
    }
  }

  return (
    <BannerAdOpenContext.Provider value={{ adOpen, setAdOpen: externalSetAdOpen }}>
      {children}
    </BannerAdOpenContext.Provider>
  );
};