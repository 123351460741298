'use client';
import { CommunityGame } from '@wt/app/community/types';
import Error from '@wt/app/error';
import { getPuzzle } from '@wt/app/game/_utils/getPuzzle';
import { useRoundProgress } from '@wt/game/providers/roundProgress/RoundProgressProvider';
import { getDayString } from '@wt/game/utils/getDayString';
import { GameMode } from '@wt/game/utils/types';
import { PuzzleLocation } from '@wt/shared/components/maps/mapkit/types';
import { Spinner } from '@wt/shared/components/spinner/spinner';
import { getCommunityGame } from '@wt/utilities/database';
import { isArray } from 'lodash';
import { NextComponentType } from 'next';
import Link from 'next/link';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

export const PUZZLE_LENGTH = 5;

const currentDate = getDayString(0);

type ComponentType =
  | NextComponentType
  | FunctionComponent<any>
  | (() => JSX.Element);

const Inner = (props: {
  error: string;
  loaded: boolean;
  Component: ComponentType;
  componentProps: any;
}) => {
  if (props.error !== '') {
    return <Error error={props.error} usePageTemplate={false} />;
  }
  if (!props.loaded) {
    return <Spinner />;
  }
  return <props.Component {...props.componentProps} />;
};

const ContentWarningError = () => {
  return (
    <section className="flex size-full flex-col items-center justify-center">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <p className="mb-8 text-3xl font-bold tracking-tight md:text-4xl">
            This game is unpublished due to a violation and is no longer
            available.
          </p>
          <p className="mb-8 text-3xl tracking-tight md:text-4xl">
            Check out dozens of other games created by WhenTaken Community
            members!
          </p>
        </div>
        <div className="mb-8 flex justify-center">
          <Link
            href={'/community'}
            prefetch={true}
            className="flex h-12 items-center justify-center gap-2.5 rounded-lg bg-accent px-4 py-2.5 md:h-14 md:rounded-xl md:px-6 md:py-4"
          >
            <div className="text-base font-bold  uppercase text-white sm:text-xl md:text-xl">
              Community
            </div>
          </Link>
        </div>
        <div className="mx-auto max-w-screen-sm text-center">
          <p className="text-md tracking-tight md:text-lg text-center px-8">
            If you’re the creator of the game and think that this game has been
            banned by mistake, feel free to reach out to us at
            contact@teuteuf.fr.
          </p>
        </div>
      </div>
    </section>
  );
};

export const PuzzleLoader = ({
  Component,
  props,
}: {
  Component: ComponentType;
  props: {
    puzzleId?: string;
    round?: string;
    gameMode: GameMode;
  };
}) => {
  const [componentProps, setComponentProps] = useState<any>(props);
  const [loaded, setLoaded] = useState<boolean>(false);
  const puzzleId = useMemo(
    () => props.puzzleId ?? getDayString(0),
    [currentDate]
  );
  const roundProgress = useRoundProgress();

  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoaded(false);
    roundProgress.setGameMode(props.gameMode);
    const promises: [
      Promise<PuzzleLocation[]>,
      Promise<CommunityGame | null>?,
    ] = [getPuzzle(puzzleId, props.gameMode)];
    if (props.gameMode == 'community') {
      promises.push(getCommunityGame(puzzleId));
    }

    Promise.all(promises)
      .then(([p, g]) => {
        if (!p || !isArray(p) || (p && isArray(p) && p.length < 1)) {
          setError('No game found!');
          return;
        }
        if (props.gameMode == 'community') {
          if (!g) {
            setError('No community game found!');
            return;
          }
          if (g.banned) {
            setError('contentwarning');
            return;
          }
          roundProgress.setCommunityGame(g);
        }
        const puzzle = p.slice(0, PUZZLE_LENGTH);
        roundProgress.setPuzzle(puzzle);
        roundProgress.setPuzzleId(puzzleId);
        setComponentProps({
          ...props,
          puzzle,
          puzzleId,
        });
        setLoaded(true);
        setError('');
      })
      .catch(() => {
        setError('No game found!');
      });
  }, [puzzleId]);

  return (
    <div className="flex size-full items-center justify-center">
      {error == 'contentwarning' ? (
        <ContentWarningError />
      ) : (
        <Inner
          error={error}
          Component={Component}
          componentProps={componentProps}
          loaded={loaded}
        />
      )}
    </div>
  );
};
