import * as whentakenThemes from '@wt/styles/themes/whentaken';
import * as wheretakenThemes from '@wt/styles/themes/wheretaken';

export function getSiteMode(): 'whentaken' | 'wheretaken' {
  const siteMode = process.env.NEXT_PUBLIC_SITE_MODE;
  return siteMode == 'wheretaken' ? 'wheretaken' : 'whentaken';
}

export function getSiteName() {
  if (getSiteMode() == 'whentaken') return 'WhenTaken';
  if (getSiteMode() == 'wheretaken') return 'WhereTaken';
  return '';
}

export function getSiteURL() {
  if (getSiteMode() == 'whentaken') return 'https://whentaken.com';
  if (getSiteMode() == 'wheretaken') return 'https://wheretaken.com';
  return '';
}

export function getCDNURL() {
  if (getSiteMode() == 'whentaken') return 'https://cdn.whentaken.com';
  if (getSiteMode() == 'wheretaken') return 'https://cdn.wheretaken.com';
  return '';
}

export function getSiteThemes() {
  if (getSiteMode() == 'whentaken')
    return {
      lightTheme: whentakenThemes.lightTheme,
      darkTheme: whentakenThemes.darkTheme,
    };
  return {
    lightTheme: wheretakenThemes.lightTheme,
    darkTheme: wheretakenThemes.darkTheme,
  };
}
