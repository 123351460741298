export function getCookie(name: string) {
  if (!document.cookie) {
    return null;
  }

  const ca: Array<string> = document.cookie.split(';');
  const cookieName = `${name}=`;

  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();

    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length);
    }
  }

  return null;
}

export function clearCookie(name: string): void {
  document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

  // Also clear older cookie
  document.cookie =
    name +
    `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.whentaken.com`;
}

export function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if (process.env.NODE_ENV === 'development') {
    document.cookie = name + '=' + (value || '') + expires + ';path=/;';
  } else {
    document.cookie =
      name + '=' + (value || '') + expires + ';path=/;SameSite=Strict;Secure';
  }
}
