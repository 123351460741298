"use client";
import "./style.css";

import PageFooter from "@wt/game/components/elements/footer";
import PageHeader from "@wt/game/components/elements/header";
import GamePageHeaderContent from "@wt/game/components/elements/header/game";
import { GameMode } from "@wt/game/utils/types";
import { Inter } from "next/font/google";
import { ReactNode, useState } from 'react';

import SettingsModal from '../modals/SettingsModal';

const interStyle = Inter({
  weight: ['400', '500', '700', '800', '900'],
  subsets: ['latin'],
});

export function PageTemplate({
  children,
  noFooter,
  noFooterMobile,
  gameMode,
}: {
  children: ReactNode;
  noFooter?: boolean;
  noFooterMobile?: boolean;
  gameMode?: GameMode;
}) {
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);

  return (
    <div
      className="mx-auto flex size-full flex-1 flex-col"
      style={interStyle.style}
    >
      <PageHeader openSettingsModal={() => setSettingsModalOpen(true)}>
        {gameMode && <GamePageHeaderContent gameMode={gameMode} />}
      </PageHeader>
      <div className="grow">{children}</div>
      {noFooter === true || gameMode ? (
        <></>
      ) : noFooterMobile === true ? (
        <div className="hidden shrink md:block">
          <PageFooter />
        </div>
      ) : (
        <div className="shrink">
          <PageFooter />
        </div>
      )}
      <SettingsModal
        open={settingsModalOpen}
        handleClose={() => setSettingsModalOpen(false)}
      />
    </div>
  );
}

export function ProtectedPageTemplate({ children }: { children: ReactNode }) {
    return (
      <div className="mx-auto flex size-full flex-1 flex-col" style={interStyle.style}>
        <div className="flex grow items-center justify-center">{children}</div>
      </div>
    );
}