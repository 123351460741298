"use client";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useSettingsContext from "@wt/game/providers/settings/types";
import Link from "next/link";
import { useTheme } from "next-themes";
import { ChangeEvent, useEffect, useState } from "react";

function SettingsModal(props: { open: boolean, handleClose: () => void }) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiBox-root': {
          border: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <SettingsContent handleClose={props.handleClose} />
      </Box>
    </Modal>
  );
}

export default SettingsModal;

const SettingsContent = (props: { handleClose: () => void }) => {
  const { theme, setTheme } = useTheme();
  const [settings, setSettings] = useSettingsContext();
  const [unit, setUnit] = useState<string>("");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      distanceUnit: e.currentTarget.value,
    });
  };

  useEffect(() => {
    if (settings) {
      setUnit(settings.distanceUnit);
    }
  }, [settings]);

  const updateTheme = (e: ChangeEvent<HTMLInputElement>) => {
    setTheme(e.currentTarget.value);
  };

  return (
    <div className="m-4 flex min-w-[300px] flex-col space-y-6 rounded-3xl bg-base-200 p-6 text-lg md:min-w-[350px]">
      <div className="flex w-full flex-row items-center">
        <div className="grow text-center">
          <span className="text-xl font-medium lg:text-4xl">Settings</span>
        </div>
        <Link href={"#"} onClick={props.handleClose}>
          <CloseRoundedIcon className="m-0 fill-current text-2xl leading-none" />
        </Link>
      </div>
      <div className="flex w-full border-b-2 border-secondary" />

      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div>Units</div>
        <div className="flex flex-row items-center">
          <input
            type="radio"
            aria-label="Distance Unit Kilometres Option"
            id="km"
            name="km"
            value="km"
            // km is default
            checked={unit !== "miles"}
            onChange={handleChange}
            className="mr-2"
          />
          <label
            htmlFor={"km"}
            className="pr-6 text-sm"
          >
            Kilometres
          </label>
          <input
            type="radio"
            aria-label="Distance Unit Miles Option"
            id="miles"
            name="miles"
            value="miles"
            checked={unit === "miles"}
            onChange={handleChange}
            className="mr-2"
          />
          <label
            htmlFor={"miles"}
            className="text-sm"
          >
            Miles
          </label>
        </div>
      </div>

      <div className="w-full border-b-2 border-secondary" />

      <div className="flex w-full flex-row items-center justify-between">
        <div>Theme</div>
        <div className="flex flex-row items-center">
          <input
            type="radio"
            aria-label="Theme Dark Option"
            id="dark"
            name="dark"
            value="dark"
            checked={theme === "dark"}
            onChange={updateTheme}
            className="mr-2"
          />
            <label
            htmlFor={"dark"}
            className="pr-6 text-sm"
          >
            Dark
          </label>
          <input
            type="radio"
            aria-label="Theme Light Option"
            id="light"
            name="light"
            value="light"
            checked={theme === "light"}
            onChange={updateTheme}
            className="mr-2"
          />
            <label
            htmlFor={"light"}
            className="text-sm"
          >
            Light
          </label>
        </div>
      </div>

    </div>
  );
}