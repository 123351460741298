import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { formatDate } from '@wt/app/archive/utils';
import { TeuteufTooltip } from '@wt/game/providers/tooltips';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export const UploadButton = () => {
  const [active, setActive] = useState(false);
  // Only visible once you've visited the site for 10 different days

  const uploadFormLink =
    getSiteMode() == 'whentaken'
      ? 'https://forms.gle/oPZZdWLePuboU17N8'
      : 'https://forms.gle/TEhkqmLYpiib81ZY9';

  useEffect(() => {
    const localStorageKey = 'days-visited';
    try {
      let daysVisited = [];
      if (localStorage.getItem(localStorageKey)) {
        daysVisited = JSON.parse(localStorage.getItem(localStorageKey)!);
      }
      const currentDate = formatDate(new Date());
      if (!daysVisited.includes(currentDate)) {
        daysVisited.push(currentDate);
      }
      localStorage.setItem(localStorageKey, JSON.stringify(daysVisited));
      if (daysVisited.length >= 10) {
        setActive(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <TeuteufTooltip
      id="upload"
      onceOnly={true}
      active={active}
      text="You can now submit your own images for our daily game!"
    >
      <Link href={uploadFormLink} target="_blank">
        <FileUploadOutlinedIcon fontSize="large" className="hidden md:flex" />
        <FileUploadOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
    </TeuteufTooltip>
  );
};
