'use client';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { nullFunc } from '@wt/utilities/nullFunction';
import Link from 'next/link';
import {
  createContext,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

interface TooltipsContextType {
  openTooltips: string[];
  setOpenTooltips: (s: string[]) => void;
}

const initialContextValue: TooltipsContextType = {
  openTooltips: [],
  setOpenTooltips: nullFunc,
};

type TeuteufTooltipProps = {
  id: string;
  text: string;
  onceOnly: boolean;
  active: boolean;
  children: ReactElement;
  localStorageKey?: string;
  onClose?: () => void;
};

const TooltipsContext = createContext<TooltipsContextType>(initialContextValue);

const useTooltips = () => useContext(TooltipsContext);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF8DD',
    boxShadow: '0px 0px 10px 0px rgba(124, 107, 11, 0.20)',
    borderRadius: '8px',
    color: '#000',
    maxWidth: 250,
    fontSize: 14,
    padding: '12px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFF8DD',
  },
}));

export const TeuteufTooltip = (props: TeuteufTooltipProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { openTooltips, setOpenTooltips } = useTooltips();

  const localStorageKey = props.localStorageKey ?? `seen-${props.id}-tooltip`;

  const isTopTooltip = openTooltips.length > 0 && openTooltips[0] == props.id;

  const inQueue = props.active && open;
  const actuallyOpen = isTopTooltip && props.active && open;

  // When this is added to the DOM...
  useEffect(() => {
    if (inQueue && !openTooltips.includes(props.id)) {
      setOpenTooltips([...openTooltips, props.id]);
    }
    if (!inQueue && openTooltips.includes(props.id)) {
      setOpenTooltips(openTooltips.filter((s) => s != props.id));
    }
  }, [inQueue, props.id, openTooltips, setOpenTooltips]);

  useEffect(() => {
    try {
      const storedSeenTooltip = localStorage.getItem(localStorageKey);
      const seenTooltip: boolean =
        storedSeenTooltip != null ? JSON.parse(storedSeenTooltip) : false;
      if (seenTooltip) return;

      setOpen(true);
    } catch (e) {
      return;
    }

    // Remove from list when unmounted
    return () => {
      if (openTooltips.includes(props.id)) {
        setOpenTooltips(openTooltips.filter((s) => s != props.id));
      }
    };
  }, []);

  useEffect(() => {
    if (props.onceOnly && actuallyOpen) {
      localStorage.setItem(localStorageKey, JSON.stringify(true));
    }
  }, [actuallyOpen, props.onceOnly]);

  const handleClickOk: MouseEventHandler = (e) => {
    setOpen(false);
    e.preventDefault();
    e.stopPropagation();
    if (props.onClose) {
      props.onClose();
    }
    return;
  };

  const handleClickDismiss: MouseEventHandler = (e) => {
    localStorage.setItem(localStorageKey, JSON.stringify(true));
    handleClickOk(e);
    return;
  };

  return (
    <HtmlTooltip
      arrow
      open={actuallyOpen}
      title={
        <div className="flex w-full flex-col items-center justify-center gap-3 text-center">
          <p>{props.text}</p>
          <div className="flex w-full items-center justify-center gap-3">
            <Link
              href={'#'}
              onClick={handleClickOk}
              className="btn h-[35px] min-h-[35px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80"
            >
              OK
            </Link>
            {!props.onceOnly && (
              <Link
                href={'#'}
                onClick={handleClickDismiss}
                className="btn h-[35px] min-h-[35px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80"
              >
                Don't show again
              </Link>
            )}
          </div>
        </div>
      }
    >
      {props.children}
    </HtmlTooltip>
  );
};

export const TooltipsProvider = ({ children }: { children: ReactNode }) => {
  const [openTooltips, setOpenTooltips] = useState<string[]>([]);

  useEffect(() => {
    if (document)
      document.body.classList.toggle('tooltip', openTooltips.length > 0);
  }, [openTooltips]);

  return (
    <TooltipsContext.Provider
      value={{
        openTooltips,
        setOpenTooltips,
      }}
    >
      {children}
    </TooltipsContext.Provider>
  );
};

export default TooltipsContext;
