import "./logo.css";

import Icon from "@wt/game/assets/logo/TeutefLogo/icon";
import Link from "next/link";
import { memo } from "react";

const TeuteufLogo = () => {
  return (
    <Link
      href={"https://teuteuf.fr/"}
      target={"_blank"}
      title="View more Teuteuf Games"
      className="inline-flex items-center justify-start gap-3 transition-opacity duration-150 ease-linear hover:opacity-80"
    >
      <div className="h-9 w-9">
        <Icon className="h-full w-full" />
      </div>
      <div className="inline-flex flex-col items-start justify-center">
        <div className="text-base font-normal ">brought to you by</div>
        <div className="text-xl font-semibold leading-none">
          TEUTEUF GAMES
        </div>
      </div>
    </Link>
  );
};

const Logo = memo(TeuteufLogo);

export default Logo;
