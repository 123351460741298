import { CommunityGame } from '@wt/app/community/types';
import { PUZZLE_LENGTH } from '@wt/app/game/_components/PuzzleLoader';
import { GameMode } from '@wt/game/utils/types';
import { PuzzleLocation } from '@wt/shared/components/maps/mapkit/types';
import { nullFunc } from '@wt/utilities/nullFunction';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

interface InProgressGuess {
  year: number | null;
  location: mapkit.Coordinate | undefined;
  locationSecondary?: mapkit.Coordinate | undefined;
}
interface RoundProgressContextType {
  score: number;
  setScore: (s: number) => void;
  puzzleId: string | undefined;
  setPuzzleId: (p: string) => void;
  numRoundsCompleted: number;
  setNumRoundsCompleted: (r: number) => void;
  currentRound: number;
  setCurrentRound: (r: number) => void;
  viewingResults: boolean;
  setViewingResults: (v: boolean) => void;
  communityGame: CommunityGame | undefined;
  setCommunityGame: (v: CommunityGame) => void;
  gameMode: GameMode;
  setGameMode: (v: GameMode) => void;
  puzzle: PuzzleLocation[];
  setPuzzle: (v: PuzzleLocation[]) => void;
  inProgressGuesses: InProgressGuess[];
  setInProgressGuesses: (g: InProgressGuess[]) => void;
}

const initialContextValue: RoundProgressContextType = {
  score: 0,
  setScore: nullFunc,
  puzzleId: undefined,
  setPuzzleId: nullFunc,
  numRoundsCompleted: 0,
  setNumRoundsCompleted: nullFunc,
  currentRound: 0,
  setCurrentRound: nullFunc,
  viewingResults: false,
  setViewingResults: nullFunc,
  communityGame: undefined,
  setCommunityGame: nullFunc,
  gameMode: 'daily',
  setGameMode: nullFunc,
  puzzle: [],
  setPuzzle: nullFunc,
  inProgressGuesses: [],
  setInProgressGuesses: nullFunc,
};

const RoundProgressContext =
  createContext<RoundProgressContextType>(initialContextValue);

export const useRoundProgress = () => useContext(RoundProgressContext);

export const RoundProgressProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [score, setScore] = useState<number>(0);
  const [puzzleId, setPuzzleId] = useState<string | undefined>(undefined);
  const [numRoundsCompleted, setNumRoundsCompleted] = useState<number>(0);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [viewingResults, setViewingResults] = useState<boolean>(false);
  const [communityGame, setCommunityGame] = useState<CommunityGame>();
  const [gameMode, setGameMode] = useState<GameMode>('daily');
  const [puzzle, setPuzzle] = useState<PuzzleLocation[]>([]);
  const [inProgressGuesses, setInProgressGuesses] = useState<InProgressGuess[]>(
    Array.from({ length: PUZZLE_LENGTH }).map(() => {
      return { year: null, location: undefined };
    })
  );

  // clear in progress guesses when the game changes
  useEffect(() => {
    setInProgressGuesses(
      Array.from({ length: PUZZLE_LENGTH }).map(() => {
        return { year: null, location: undefined };
      })
    );
  }, [puzzleId]);

  return (
    <RoundProgressContext.Provider
      value={{
        score,
        setScore,
        puzzleId,
        setPuzzleId,
        numRoundsCompleted,
        setNumRoundsCompleted,
        currentRound,
        setCurrentRound,
        viewingResults,
        setViewingResults,
        communityGame,
        setCommunityGame,
        gameMode,
        setGameMode,
        puzzle,
        setPuzzle,
        inProgressGuesses,
        setInProgressGuesses,
      }}
    >
      {children}
    </RoundProgressContext.Provider>
  );
};