"use client";
import { usePathname, useRouter } from "next/navigation";

export default function useAnimatedRouter() {
  const pathName = usePathname();
  const router = useRouter();
  // Navigate to the new route
  const prefetchRoute  = (url: string) => {
      router.prefetch(url);
  }
  const animatedRoute = (url: string) => {
    if (url !== pathName) {
      return router.push(url);
    }
    return undefined;
  };
  return { animatedRoute, prefetchRoute };
}
